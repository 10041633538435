body,
html {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #0d1c28;
  zoom: 1;
  -moz-transform: scale(1);
  -moz-transform-origin: 0 0;
  -o-transform: scale(1);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(1);
  -webkit-transform-origin: 0 0;
  touch-action: none;
}
/*Fonts */
@font-face {
  font-family: 'LogoFont';
  src: url('./Fonts/Rosewell.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
#root {
  height: 100%;
  width: 100%;
  zoom: 1;
  -moz-transform: scale(1);
  -moz-transform-origin: 0 0;
  -o-transform: scale(1);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(1);
  -webkit-transform-origin: 0 0;
}
.app {
  flex-direction: column;
  display: flex;
}
.screen {
  position: relative;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.screen::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  border-left: 1px dotted white;
  height: 100%;
  opacity: 0.5;
}

.splash {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
}
.splash-text {
  margin: auto;
  margin-bottom: 3vh;
  font-style: italic;
  opacity: 50%;
  color: white;
}
.splash-image-block {
  display: flex;
  flex-direction: column;
  margin-top: 20vh;
}

.image-splash-logo {
  margin: auto;
  background-color: #fff;
  width: 80%;
}
.image-splash-load {
  margin: auto;

  object-fit: cover;
  width: 10%;
  margin-top: 30%;
}
.button-group {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
}

.signup-names {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin-top: 2vh;
}
.name-inputs {
  width: 100%;
}
.surname-inputs {
  display: flex;
  flex-direction: row-reverse;
}
.input-div-surname {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
  margin-bottom: 1vh;
}
.space-between-name {
  width: 1vh;
}
.gap {
  width: 1vh;
}
.left-arrow-hided {
  margin: auto;
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: green;
  align-items: flex-end;
  font-size: 5vh;
}

.right-arrow-hided {
  margin: auto;
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: red;
  font-size: 5vh;
  align-items: flex-start;
}
.form-to-send {
  display: flex;
  border: none;
  margin-bottom: 5vh;
  justify-content: center;
}
.all-screen-comment-sent{
width: 100%;
height: 100%;
background-color: black;


}
.form-to-send-screen {
  flex-direction: column;
  display: flex;
  width: 100%;
  background-color: #0d1c28;
}
.comment-send-small-triangle {
  display: flex;
  justify-content: center;
  margin-bottom: -1vh;
}
.comment-send {
  display: flex;
  margin-top: 5vh;
  justify-content: center;
}
.button-send {
  width: 80%;
  height: 7vh;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 5vh;
  border-radius: 10px;
  border: none;
  color:white;
  background-color: #fe1a79;
}
.button-send-cancel {
  width: 80%;
  height: 7vh;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 5vh;
  border-radius: 10px;
  border: none;
  background-color: #fe1a79;
  opacity: 50%;
  color:white;
}
.comment-up {
  display: flex;
  justify-content: center;

  color: green;
  width: 100%;
  font-size: 5vh;
}
.arrow-back {
  background-color: black;
  width: 30px;
  height: 30px;
  margin: 2vh;
  margin-bottom: 3vh;
  object-fit: fill;
  display: flex;
  align-items: center;
}
.arrow-back-recovery {
  background-color: black;
  width: 5vh;
  margin-left: 2vh;
  margin-top: 2vh;
  object-fit: cover;
}
.circle-three-points {
  position: absolute;
  top: -3.5vh;
  right: 50%;
  margin-right: -3.5vh;
  width: 7vh;
  height: 7vh;
  border-radius: 100%;
  background-color: rgb(36, 36, 36);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-blob {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 200px;
  overflow: hidden;
  filter: brightness(90%);
  border: 20px solid #0d1c28;
  background-color: #0d1c28;
}
.three-points {
  width: 3vh;
  height: 3vh;
}
.recovery-screen-text {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-left: 2vh;
  margin-right: 2vh;
  margin-bottom: 2vh;
  text-align: center;
}
.screen-timer {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.text-signup-up {
  display: flex;
  flex-direction: row;
  justify-content: center;
 
  color: white;
}
.text-email-sent {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 3vh;
  color: white;
  height: 40%;
  align-content: flex-end;
}
.login-screen {
  overflow: auto;
  height: 100%;
}

.hello-text-login {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}
.hello-text-div {
  margin-top: -3.5vh;
}

.login-container {
  width: 100%;
  height: 100%;
  color: black;

  position: relative;
}
.textarea-comment-send {
  border: none;
  border-radius: 1vh;
  width: 80%;
  font-size: 16px;
}

.login-group {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;

}

.labels-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 90%;
  margin-bottom: 0.5vh;
}

.login-title {
  margin-bottom: 2vh;
  margin-top: 2vh;
  display: flex;
  justify-content: center;
}
.header-login {
  margin: auto;
}
.header-login-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  color: white;
  font-family: LogoFont;
  font-size: 300%;
  font-weight: bold;
  height: 10vh;
  margin-top: 2vh;

  align-items: center;
}
.curve-1 {
  position: absolute;
  top: -3vh;
  left: -7vh;
  width: 16vh;
  height: 16vh;
  border: 1px solid white;
  border-radius: 16vh 16vh 16vh 16vh;
}

.curve-2 {
  position: absolute;
  top: -4vh;
  left: -10vh;
  width: 16vh;
  height: 16vh;
  border: 1px solid white;
  border-radius: 16vh 16vh 16vh 16vh;
}
.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width:100%
}

.input-div {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1vh;
  margin-bottom: 1vh;
}
.input-login {
  border-radius: 5px;
  border-width: 1px;
  padding: 1.5vh;
  border-color: black;
  width: 100%;
}
input-login::placeholder {
  color: #999;
  padding-left: 10px;
}
.login-button {
  margin: 3vh;
  border-radius: 10px;
  border: none;
  background-color: #fe1a79;
  color: white;
  width: 90%;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
}
.login-button-google {
  display: flex;
  justify-content: center;
  cursor: pointer;

  align-items: center;
  margin: 3vh;
  border-radius: 10px;
  border: solid 1px white;
  color: white;
  width: 90%;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  background-color: #0d1c28;
}

.logo-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
}
.error-message {
  margin-top: 1vh;
  color: red;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 90%;
  margin-bottom: 0.5vh;
}
.div-signup-me {
  display: flex;
  text-decoration: underline;
  justify-content: center;
  color: white;
}
.google-button {
  max-width: 450px;
  display: flex;
 
  border-radius: 10px;
  border: none;
  border: 1px solid white;
  color: white;
  width: 90%;
  position: relative;
  z-index: 2;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  margin: auto;
  margin-top: 2vh;
  justify-content: center;
}
.google-icon {
  position: absolute;
  z-index: 1;
  left: 20px;
}
.hided {
  opacity: 0;
  flex: 1;
  left: 20px;
  position: absolute;
  z-index: 1;
}

.signup-button {
  padding-left: 5vh;
  padding-right: 5vh;
  margin: auto;
  border-radius: 1vh;
  border-color: rgb(67, 16, 16);
  border-width: 0.5vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  width: 50%;
  background-color: rgb(87, 82, 82);
  color: white;
}
.error-screen {
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.div-forgot-password {
  width: 90%;
  display: flex;
  flex-direction: row-reverse;
}

.error-text {
  display: flex;
  flex-direction: column;
  margin: 2vh;

  align-items: center;
}
.error-page-text{
  color: #fe1a79;
  width:100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
margin-top:5vh;
margin: 5px;
}
.gender-box {
  display: flex;
  flex-direction: row;
  margin-top: 2vh;
  margin-bottom: 2vh;
  flex-wrap: wrap;
  width:90%;
}
.gender-text {
  width: 90%;
  margin: auto;
  display: flex;
  
}
/* Header styles */
.header-layout {
  background-color: #0d1c28;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 23vh;
}
.layout {
  height: 100%;
  width: 100%;
}
.language-block {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.language-selected {
  color: #fe1a79;
}

/*Header*/
/* HamburgerMenu.css */
.hamburger-menu {
  position: fixed;
  top: -100%;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #0d1c28;
  transition: top 0.3s ease;
}

.hamburger-menu.open {
  top: 0;
  width: 100%;
  z-index: 9999;
}

.hamburger-icon {
  display: flex;
  flex-direction: column;
  padding: 20px;
  cursor: pointer;
  align-items: flex-end;
  font-weight: bold;
}

.line {
  width: 30px;
  height: 3px;
  background-color: #fff;
  margin: 3px 0;
}

.menu-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-weight: bold;
}

.menu-items li {
  padding: 20px;
  color: #fff;
  text-align: center;
}
.hamburger-header-icon-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.line-menu {
  width: 80%;
  height: 1px;
  background-color: white;
  margin: 0 auto;
}
.header-text {
  display: flex;
  flex-direction: column;
  width: 60%;
  font-size: 2.5vh;
  padding-left: 3vh;
  justify-content: center;
  font-weight: bolder;
}
.show-name-main {
  color: #fe1a79;
}
.header-text-line {
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  display: flex;
  flex-direction: row;
}
.header-text-line-1 {
  margin-top: 0.2vh;
  font-size: 2vh;
}
.header-text-line-2 {
  margin-top: 0.2vh;
  font-size: 1.8vh;
}

.main-screen {
  height: 100%;
  background-color: black;
  width: 100%;
}

.how-it-work-button {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  margin-top: 1vh;
  margin-left: 1vh;
  border: none;
  display: flex;
  font-size: small;
  margin-right: 1vh;
  justify-content: center;
  align-items: center;
}

.footer-main-screen {
  background: #0d1c28;
  width: 100%;
  height: 20%;
  z-index: 1;
  display: flex;
  flex-direction: column;

  justify-content: space-evenly;
  position: relative;
}
.footer-text {
  display: flex;
  margin-bottom: 25px;
  font-size: small;
  flex-wrap: wrap;
  font-weight: bolder;
  text-align: center;
}
.div-block-footer {
  display: flex;
  margin-top: 5vh;
  flex-direction: column;
  width: 70%;
  align-self: center;
  justify-content: center;
  background-color: #0d1c28;
  color: #fe1a79;
  align-items: center;
}
.footer-main-clock {
  display: flex;
  flex-direction: column;
  margin: auto;
  height: inherit;
  background-color: rgba(0, 0, 0, 0);
  align-items: center;
}
.vertical-line-footer {
  background-color: white;

  margin: 1vh;
  width: 1px;
  height: 10vh;
}
.gap-down-mainscreen {
  height: 20vh;
}
.button-main {
  height: 7vh;
  background-color: white;
  opacity: 100%;
  width: 80%;
  align-self: center;
  border-radius: 2vh;
  margin-top: 1vh;
  margin-bottom: 2vh;
}
.image-box-footer {
  width: 10vh;
  height: 10vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
}
.clock-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 100%;
}
.show-duration-in-footer {
  color: white;
  align-self: center;
  font-weight: bold;
  margin-top: 1vh;
}
.my-shows {
  width: 100%;
  height: 76vh;
  color: white;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.timer-names {
  display: flex;
  flex-direction: column;
}
.div-inside-button-shows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
 
  font-size: larger;
  margin: 20px 10px 20px 10px;

  color: #fff;
}
.chat-icon {
  width: 40%;
}
.container-of-touchable {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  height: 45%;
  margin: auto;
}
.center-line {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  width: 60%;
}

.timer-in-card {
  display: flex;
  font-size: x-large;
  font-weight: bold;
}

.shows-name-text-in-button {
  font-weight: bold;
  margin: 10px;
  font-size: medium;
  display: flex;
  margin-left: 5%;
}
.link-text-inside-button {
  margin-right: 2vh;
  color: #fe1a79;
}
.passed-show-text {
  margin-left: 5%;
  font-weight: bold;
  margin-top: 5vh;
}
.button-shows {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 5%;
  border-radius: 2vh;
}
.ended-shows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  align-self: center;
  margin-bottom: 5vh;

  margin-top: 5vh;
}
.old-shows-name {
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  width: 27vh;
}
.old-shows-params {
  font-size: small;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.old-shows-name-arrow {
  font-size: large;
  font-weight: bold;
}
.old-shows-parent-div {
  display: flex;
  flex-direction: column;
}
.line-old-shows {
  width: 90%;
  height: 1px;
  background-color: white;
  margin: 0 auto;
}
.image-container-ended-show-screen {
  display: flex;
  width: 100%;
  height: 30vh;
  flex-direction: column-reverse;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.text-div-history {
  color: white;
  display: flex;
  flex-direction: column;
  height: 30vh;
  font-weight: bold;
  margin: 3vh;
  margin-bottom: 0vh;
}
.show-result-and-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ended-show-status {
  color: #fe1a79;
  display: flex;
  align-items: center;
}
.users-opinions-div {
  justify-content: space-evenly;
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  height: 15vh;
}
.total-reactions-div {
  display: flex;
  justify-content: space-between;
}
.button-down-old-show {
  display: flex;
  justify-content: center;
  margin-bottom: 5vh;
}
.button-text-line {
  display: flex;
  margin-top: 3px;
  margin-bottom: 3px;
}
.privacy-and-terms {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.privacy-and-terms-text {
  margin: 2vh;
  overflow: auto;
  height: 63vh;
  margin-bottom: 5vh;
}
.how-it-works-main {
  display: flex;
  flex-direction: column;
  color: #fff;
  align-items: center;
  overflow: auto;
}
.header-how-it-works {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  align-items: center;
}
.text-how-it-works {
  background-color: #fff;
  color: black;
  width: 70%;
  height: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.button-i-understand {
  background-color: #fe1a79;
  height: 48px;
  border-radius: 24px;
  font-weight: bold;
}
